<template>
  <v-container
    id="notifications"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-row>
      <v-col cols="12">
        <v-card style="margin-top: 0; padding: 20px;">
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                class="row-flex-align-space"
              >
                <div>
                  <v-btn
                    color="secondary"
                    @click="openDialog()"
                  >
                    {{ str['edit_data'] }}
                  </v-btn>
                  <v-btn
                    v-if="isLiveActive && !isProcessing"
                    color="warning"
                    @click="reset()"
                    class="ml-3"
                  >
                    {{ str['finish'] }}
                  </v-btn>
                </div>
                <div
                  v-if="!isLiveActive"
                  class="row-flex-align cursor-hover"
                  @click="openDialog()"
                >
                  <span class="mr-1">
                    {{ str['offline'] }}
                  </span>
                  <v-icon
                    color="default"
                  >
                    mdi-circle
                  </v-icon>
                </div>
                <div
                  v-if="isLiveActive && !isProcessing"
                  class="row-flex-align cursor-hover"
                  @click="reset()"
                >
                  <span class="mr-1">
                    {{ str[liveActiveText] }}
                  </span>
                  <v-icon
                    :color="liveActiveText === 'online' ? 'success' : 'warning'"
                  >
                    mdi-circle
                  </v-icon>
                </div>
              </v-col>
              <v-col
                v-if="!isLiveActive && !isProcessing"
                cols="12"
                class="pt-8 pb-8 text-center"
              >
                <h4>
                  {{ str['live_empty'] }}
                </h4>
              </v-col>
              <v-col
                v-if="isProcessing"
                cols="12"
                class="pt-8 pb-8 text-center"
              >
                <h4>
                  {{ str['processing'] }}
                </h4>
              </v-col>
              <v-col
                v-if="isLiveActive && !isProcessing"
                cols="12"
                class="pb-0"
              >
                <b>{{ str['link'] }}</b>: {{ liveUrl }}
              </v-col>
              <v-col
                v-if="isLiveActive && !isProcessing"
                cols="12"
                class="pb-0"
              >
                <b>{{ str['start_date'] }}</b>: {{ liveStart }}
              </v-col>
              <v-col
                v-if="isLiveActive && !isProcessing"
                cols="12"
                class="pb-0"
              >
                <b>{{ str['start_date'] }}</b>: {{ liveEnd }}
              </v-col>
              <v-col
                v-if="isLiveActive && !isProcessing"
                cols="12"
                class="mt-6 text-center"
              >
                <iframe
                  :src="getVideoUrl(liveUrl)"
                  frameborder="0"
                  width="400"
                  height="400"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogFields"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <div>
            <span class="headline">
              {{ str['edit_data'] }}
            </span>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="liveUrl"
                :label="str['url']"
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedLiveStart"
                    :label="str['start_date']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-card class="no-margin">
                  <v-date-picker
                    v-model="liveStartDate"
                    style="margin: 0;"
                    @change="updateStartDate"
                    :locale="datepickerLanguage"
                  />
                  <v-row class="pa-2 datepicker-time-selects">
                    <v-col class="pr-1">
                      <v-select
                        v-model="liveStartHour"
                        :items="hours"
                        :label="str['hours']"
                        solo
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col class="pl-1">
                      <v-select
                        v-model="liveStartMinute"
                        :items="minutes"
                        :label="str['minutes']"
                        solo
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="default"
                      @click="startMenu = false"
                    >
                      {{ str['cancel'] }}
                    </v-btn>
                    <v-btn
                      color="success"
                      @click="saveStart"
                      :disabled="!liveStartHour || !liveStartMinute"
                    >
                      {{ str['ok'] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="endMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedLiveEnd"
                    :label="str['end_date']"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-card class="no-margin">
                  <v-date-picker
                    v-model="liveEndDate"
                    style="margin: 0;"
                    @change="updateEndDate"
                    :locale="datepickerLanguage"
                  />
                  <v-row class="pa-2 datepicker-time-selects">
                    <v-col class="pr-1">
                      <v-select
                        v-model="liveEndHour"
                        :items="hours"
                        :label="str['hours']"
                        solo
                        dense
                        hide-details
                      />
                    </v-col>
                    <v-col class="pl-1">
                      <v-select
                        v-model="liveEndMinute"
                        :items="minutes"
                        :label="str['minutes']"
                        solo
                        dense
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="default"
                      @click="endMenu = false"
                    >
                      {{ str['cancel'] }}
                    </v-btn>
                    <v-btn
                      color="success"
                      @click="saveEnd"
                      :disabled="!liveEndHour || !liveEndMinute"
                    >
                      {{ str['ok'] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeDialog()"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="save()"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import axios from 'axios'
  import moment from 'moment'

  export default {
    data () {
      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: Utils.getUser(),
        settings: [],
        dialogFields: false,
        startMenu: false,
        endMenu: false,
        liveUrl: '',
        liveStartDate: null,
        liveStartHour: null,
        liveStartMinute: null,
        liveStart: '',
        liveEndDate: null,
        liveEndHour: null,
        liveEndMinute: null,
        liveEnd: '',
        hours: Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0')),
        minutes: Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0')),
        getVideoUrl: Utils.getYoutubeEmbedUrl,
        isLiveActive: false,
        liveActiveText: '',
        isProcessing: false,
      }
    },
    computed: {
      formattedLiveStart() {
        if (this.liveStartDate && this.liveStartHour !== null && this.liveStartMinute !== null) {
          return `${this.liveStartDate} ${this.liveStartHour}:${this.liveStartMinute}`
        }
        return ''
      },
      formattedLiveEnd() {
        if (this.liveEndDate && this.liveEndHour !== null && this.liveEndMinute !== null) {
          return `${this.liveEndDate} ${this.liveEndHour}:${this.liveEndMinute}`
        }
        return ''
      }
    },
    watch: {
      dialogFields (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeDialog()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.get()
    },
    methods: {
      get: function () {
        const self = this
        this.$isLoading(true)
        this.isProcessing = true
        Api.getSettings({
          id: axios.defaults.ptId,
        }, function (response) {
          if (response.success) {
            self.refreshData(response.data)
          } else {
            self.$isLoading(false)
            self.isProcessing = false
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      refreshData: function (data) {
        const self = this
        if (data) {
          this.settings = data
        }
        this.liveUrl = this.settings.find(s => s.name === 'live_url').value
        const startSetting = this.settings.find(s => s.name === 'live_start')
        if (startSetting) {
          if (startSetting.value) {
            [this.liveStartDate, this.liveStartHour, this.liveStartMinute] = startSetting.value.split(/[\s:]/)
            this.liveStart = Utils.convertUtcDate(`${this.liveStartDate} ${this.liveStartHour}:${this.liveStartMinute}`)
            this.liveStartDate = moment(this.liveStart).format('YYYY-MM-DD')
            this.liveStartHour = moment(this.liveStart).format('HH')
            this.liveStartMinute = moment(this.liveStart).format('mm')
          } else {
            this.liveStart = ''
            this.liveStartDate = ''
            this.liveStartHour = ''
            this.liveStartMinute = ''
          }
        }
        const endSetting = this.settings.find(s => s.name === 'live_end')
        if (endSetting) {
          if (endSetting.value) {
            [this.liveEndDate, this.liveEndHour, this.liveEndMinute] = endSetting.value.split(/[\s:]/)
            this.liveEnd = Utils.convertUtcDate(`${this.liveEndDate} ${this.liveEndHour}:${this.liveEndMinute}`)
            this.liveEndDate = moment(this.liveEnd).format('YYYY-MM-DD')
            this.liveEndHour = moment(this.liveEnd).format('HH')
            this.liveEndMinute = moment(this.liveEnd).format('mm')
          } else {
            this.liveEnd = ''
            this.liveEndDate = ''
            this.liveEndHour = ''
            this.liveEndMinute = ''
          }
        }
        if (this.liveUrl) {
          Api.getAlerts({
            id: 1
          }, function (response) {
            self.$isLoading(false)
            self.isProcessing = false
            if (response.success) {
              self.liveActiveText = response.data && response.data.live_url ? 'online' : 'offline'
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
            self.isLiveActive = true
          })
        } else {
          this.$isLoading(false)
          this.isProcessing = false
          this.isLiveActive = false
        }
      },
      updateStartDate: function () {
        this.showStartTimePicker = true
      },
      updateStartTime: function () {
        this.showStartTimePicker = false
        this.saveStart()
      },
      saveStart: function () {
        this.liveStart = `${this.liveStartDate} ${this.liveStartHour}:${this.liveStartMinute}`
        this.startMenu = false
      },
      updateEndDate: function () {
        this.showEndTimePicker = true
      },
      updateEndTime: function () {
        this.showEndTimePicker = false
        this.saveEnd()
      },
      saveEnd: function () {
        this.liveEnd = `${this.liveEndDate} ${this.liveEndHour}:${this.liveEndMinute}`
        this.endMenu = false
      },
      reset: function () {
        const self = this
        this.$confirm(
          window.strings['are_you_sure_cancel_live'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.startMenu = false
          self.endMenu = false
          self.liveUrl = ''
          self.liveStartDate = null
          self.liveStartHour = null
          self.liveStartMinute = null
          self.liveStart = ''
          self.liveEndDate = null
          self.liveEndHour = null
          self.liveEndMinute = null
          self.liveEnd = ''
          self.save(true)
        }).catch(() => { })
      },
      save: function (reset) {
        const self = this
        if (!reset && (!this.liveUrl || !this.liveStart || !this.liveEnd)) {
          return this.$alert(
            window.strings['fields_to_be_filled'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
        this.$isLoading(true)
        for (let i = 0; i < this.settings.length; i++) {
          if (this.settings[i].name === 'live_url') {
            this.settings[i].value = this.liveUrl
          }
          if (this.settings[i].name === 'live_start') {
            this.settings[i].value = this.liveStart ? Utils.convertDateToUtc(this.liveStart) : ''
          }
          if (this.settings[i].name === 'live_end') {
            this.settings[i].value = this.liveEnd ? Utils.convertDateToUtc(this.liveEnd) : ''
          }
        }
        Api.updateSettings({
          id: axios.defaults.ptId,
          settings: this.settings
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.closeDialog()
            self.refreshData(response.data)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openDialog: function () {
        if (!this.isLiveActive && !this.liveStart && !this.liveEnd) {
          const start = moment()
          this.liveStart = start.format('YYYY-MM-DD HH:00')
          this.liveStartDate = start.format('YYYY-MM-DD')
          this.liveStartHour = start.format('HH')
          this.liveStartMinute = '00'
          const end = moment().add(1, 'hours')
          this.liveEnd = end.format('YYYY-MM-DD HH:00')
          this.liveEndDate = end.format('YYYY-MM-DD')
          this.liveEndHour = end.format('HH')
          this.liveEndMinute = '00'
        }
        this.dialogFields = true
      },
      closeDialog: function () {
       this.dialogFields = false
      },
    },
  }
</script>
